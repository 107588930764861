export class Audio {
  constructor() {
    this.timer = Date.now();
    this.lastBeatCheck = Date.now();
    this.bpm = 60 / 140;
    this.playing = false;
    this.audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    this.playing = false;
    this.volume = this.audioContext.createGain();
    this.volume.connect(this.audioContext.destination);
    this.bass = this.audioContext.createOscillator();
    this.volume.gain.value = 1;
    this.bass.type = "sine";
    this.bass.frequency.setValueAtTime(500, this.audioContext.currentTime);
    this.bass.connect(this.volume);
    this.bass.connect(this.audioContext.destination);
  }

  startSound = () => {
    this.bass.start();
    this.playing = true;
  };

  music = () => {
    if (!this.playing) this.startSound();

    if ((Date.now() - this.lastBeatCheck) / 1000 > this.bpm) {
      this.lastBeatCheck = Date.now();
    }
    this.volume.gain.value = Math.sin((Date.now() - this.lastBeatCheck) / 1000);
  };
}
