import { Circle } from './Circle.js';
import { Audio } from './Audio.js';
import {BackgroundSquare} from './BackgroundSquare.js';

export class Game {
	constructor(ctx, canvas, music, popRef) {
		if(	document.documentElement.webkitRequestFullscreen)
			document.documentElement.webkitRequestFullscreen();

		this.music = music;
		this.circles = [];
		this.gameover = false;
		this.mousePos = {};
		this.pressHappened = false;
		this.popSound = popRef.current;
		this.raf = null;

		this.fps = 0;
		this.lastCheckedTime = Date.now();

		this.timer = Date.now();

		this.timeStart = Date.now();
		this.backgroundTime = Date.now();
		this.lastBackgroundTime = Date.now();
		this.lastBackgroundChange = Date.now();

		this.pressHappened = false;
		this.mousePos = { x: 0, y: 0 };

		this.color = [ 0, 255, 100 ];
		this.spawnRate = 1;
		this.multiplier = 1;

		this.score = 0;

		this.totalTouches = 0;

		this.backgroundSquares = [];
		this.background = '#1A1C1A';

		this.lastSpawn = Date.now();

		this.numBackgroundEffects = 6;
		this.backgroundEffect = Math.floor(Math.random() * this.numBackgroundEffects);
		console.log(this.backgroundEffect);

		this.colors = [ '#1A1C1A' ]; //['#273D6A','#430005','#4E225E','#1A1B41', '#1E132C'];
		this.currentColor = 0;

		this.ctx = canvas.current.getContext('2d');
		this.raf = requestAnimationFrame(this.update);
		canvas.current.width = window.innerWidth;
		canvas.current.height = window.innerHeight;
		this.canvasWidth = window.innerWidth;
		this.canvasHeight = window.innerHeight;
		this.canvas = canvas;
		/*this.circles.push(
			new Circle(
				Math.random() * (window.innerWidth - window.innerWidth / 2) + window.innerWidth / 2,
				Math.random() * (window.innerHeight - window.innerHeight / 4) + window.innerHeight / 4,
				this.ctx,
				this.canvas
			)
		);*/

		this.Audio = new Audio();
		this.canvas.current.addEventListener('mousedown', this.checkCollision);
	}

	getMousePosition = (e) => {
		let rect = this.canvas.current.getBoundingClientRect();
		return { x: e.clientX - rect.left, y: e.clientY - rect.top };
	};
	checkCollision = (e) => {
		this.mousePos = this.getMousePosition(e);
		this.pressHappened = true;
		this.totalTouches++;

		if (this.totalTouches && !this.music.current.playing) {
			this.music.current.play();
		}
	};

	update = () => {
		if ((Date.now() - this.timer) / 1000 > 50) {
			this.timer = Date.now();
			this.spawnRate -= 0.1;
		}
		console.log(this.spawnRate);
		if (!this.gameover) {
			this.ctx.font = this.canvasWidth / 3 + 'px verdana';
			this.ctx.fillText(this.score, this.canvasWidth / 2, this.canvasHeight / 2 + this.canvasHeight / 2 / 1.5);
			this.ctx.globalAlpha = 1;
			this.backgroundColor();
			// make bg transparent
			this.ctx.globalAlpha = .4;
			this.ctx.fillStyle = this.background;

			this.ctx.fillRect(0, 0, this.canvasWidth, this.canvasHeight);
			this.ctx.globalAlpha = 1.0;

			this.ctx.fillStyle = '#000000';
			this.ctx.textAlign = 'center';
			//this.showFps(true);

			if (this.totalTouches < 1) {
				this.raf = requestAnimationFrame(this.update);
				return;
			}

			this.circles.forEach((circle) => {
				if (circle.update(this.pressHappened, this.mousePos)) {
					this.pressHappened = false;
				}
			});

			this.ctx.fillStyle = '#FFFFFF';
			this.ctx.textAlign = 'center';

			this.ctx.font = this.canvasWidth / 3 + 'px verdana';
			this.ctx.fillText(this.score, this.canvasWidth / 2, this.canvasHeight / 2 + this.canvasHeight / 2 / 1.5);


			this.circles.forEach((circle) => {
				if (circle && circle.dead) {
					this.score++;
				} else if (circle && circle.gameover) {
					this.gameover = true;
				}
			});


			this.ctx.font = this.canvasWidth / 30 + 'px verdana';
			this.accuracy = Math.round((this.score/(this.totalTouches-1)) * 100);
			this.ctx.fillText((this.accuracy ? this.accuracy : 0) + "%", this.canvasWidth / 2, this.canvasHeight / 2 + this.canvasHeight / 2.5);



			this.circles.map((circle, i) => {
				if (circle && circle.dead) {
					this.popSound.pause();
					this.popSound.currentTime = 0;
					this.popSound.play();
					this.circles.splice(i, 1);
				}
			});

			if ((Date.now() - this.lastSpawn) / 1000 > this.spawnRate) {
				// this.circles.length < this.spawnRate) {
				this.circles.push(
					new Circle(
						Math.random() * (window.innerWidth - window.innerWidth / 3) + window.innerWidth / 6,
						Math.random() * (window.innerHeight - window.innerHeight / 4) + window.innerHeight / 6,
						this.ctx,
						this.canvas
					)
				);
				this.lastSpawn = Date.now();
			}
			this.raf = requestAnimationFrame(this.update);
			this.pressHappened = false;
		}
	};

	showFps = (show) => {
		if (show) {
			let delta = (Date.now() - this.lastCheckedTime) / 1000;
			this.lastCheckedTime = Date.now();
			this.fps = Math.round(1 / delta);
		}
		this.ctx.fillStyle = '#00EE00';
		this.ctx.font = '30px verdana';
		this.ctx.fillText(this.fps, 35, 35);
	};

	backgroundColor = () => {
		let delta = (this.backgroundTime - this.lastBackgroundTime) / 1000;
		this.backgroundTime = Date.now();
		if (delta >= 60 / 144) {
			this.lastBackgroundTime = Date.now();
			this.currentColor = Math.floor(Math.random() * this.colors.length);
			/*	this.color[0] = Math.round(Math.random() * 255) + 100;
			this.color[1] = Math.round(Math.random() * 255) + 100;
	  this.color[2] = Math.round(Math.random() * 255) + 100;*/

			if ((Date.now() - this.lastBackgroundChange) / 1000 > 50) {
				this.backgroundEffect = Math.floor(Math.random() * this.numBackgroundEffects);
				this.lastBackgroundChange = Date.now();
			}

			this.backgroundSquares.push(new BackgroundSquare(this.canvas, this.backgroundEffect));
		}

		this.ctx.fillStyle = this.colors[this.currentColor]; ///'rgb(' + this.color[0] + ',' + this.color[1] + ',' + this.color[2] + ')';
		this.ctx.fillRect(0, 0, this.canvasWidth, this.canvasHeight);

		this.backgroundSquares.forEach((bg, index) => {
			if (!bg.update()) {
				this.backgroundSquares.splice(index, 1);
			}
		});
	};
}
