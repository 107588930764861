export class Circle {
  constructor(x, y, ctx, canvas) {
    this.x = x;
    this.y = y;
    this.ctx = ctx;
    this.radius = 50;
    this.growthSpeed = 2;
    this.canvas = canvas;
    this.lastChecked = Date.now();
    this.dead = false;
    this.gameover = false;
    

    this.colorTime = Date.now();
    this.lastColorTime = Date.now();

    this.colors = ['#08F7FE','#09FBD3','#FE53BB','#F5D300'];
    this.currentColor  = Math.floor(Math.random() * this.colors.length)

  }

  checkCollision = (touch, mousePos) => {
    if (this.radius * 2 > this.canvas.current.height) this.gameover = true;
    if (
      touch &&
      mousePos.x > this.x - this.radius &&
      mousePos.x < this.x + this.radius &&
      mousePos.y > this.y - this.radius &&
      mousePos.y < this.y + this.radius * 2
    ) {
      this.dead = true;
      return true;
    }
    return false;
  };

  update = (touch, mousePos) => {
    this.draw();
    this.changeColor();
    if (this.dead) return;

    this.grow();

    if(this.checkCollision(touch, mousePos)) {
        return true;
    }

    return false;
  };

  draw = () => {
    this.ctx.beginPath();
    this.ctx.arc(this.x, this.y, this.radius, 0, (this.radius*2) * Math.PI, false);
    this.ctx.fillStyle = "#00000";
   // this.ctx.strokeStyle = "#00000";
    this.ctx.lineWidth = -1;

    this.ctx.stroke();
  };
  grow = () => {
    let delta = (Date.now() - this.lastChecked) / 1000;
    this.radius += this.growthSpeed * delta;
    this.time = Date.now();
  };
  changeColor = () => {
  	let delta = (this.colorTime - this.lastColorTime) / 1000;
		this.colorTime = Date.now();
		if (delta >= (60 / 144)) {

   
      this.lastColorTime = Date.now();
      this.currentColor = Math.floor(Math.random() * this.colors.length)
		}

    this.ctx.strokeStyle =this.colors[this.currentColor];
		this.ctx.fillStyle = this.colors[this.currentColor];
    this.ctx.fill();
  };
}
