export class BackgroundSquare {
	constructor(canvas, effect) {
		this.ctx = canvas.current.getContext('2d');
		this.radius = 0;

		this.wRatio = window.innerHeight < window.innerWidth ? window.innerWidth / window.innerHeight : 1;
		this.hRatio = window.innerHeight > window.innerWidth ? window.innerHeight / window.innerWidth : 1;

		this.canvasHeight = window.innerHeight;
		this.canvasWidth = window.innerWidth;

		this.lastChecked = Date.now();
		this.growthSpeed = 0.01;
		this.canvas = canvas;
		this.background = '#1A1C1A';

		this.colors = [ '#08F7FE', '#09FBD3', '#FE53BB', '#F5D300' ]; // ['#39ff14'];//['#26A65B']; //['#08F7FE','#09FBD3','#FE53BB','#F5D300'];
		this.color = Math.floor(Math.random() * this.colors.length); //'#00FF00'

		this.thickness = 2;
		//this.color =  this.colors[Math.floor(Math.random() * this.colors.length)];
		this.effect = effect;
	}

	update() {
        if (this.effect == 0)return this.backgroudOne();
       	else if (this.effect == 1) return this.backgroudTwo();
		else if (this.effect == 2) return this.backgroudThree();
        else if (this.effect == 3) return this.backgroudFour();
        else if (this.effect == 4) return this.backgroudFive();
        else if (this.effect == 5) return this.backgroudSix();
	}

	backgroudTwo() {
		let delta = (Date.now() - this.lastChecked) / 1000;
		this.radius += this.growthSpeed * delta;
		/*
    this.ctx.strokeStyle = this.colors[Math.floor(Math.random() * this.colors.length)]; // '#39ff14';
    this.ctx.lineWidth = 1.5;

    this.ctx.beginPath();
    this.ctx.moveTo((this.canvasWidth/2)+.5, (this.canvasHeight/2)+.5);
    this.ctx.lineTo(0, 0);
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.moveTo((this.canvasWidth/2)+.5, (this.canvasHeight/2)+.5);
    this.ctx.lineTo(this.canvasWidth, 0);
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.moveTo((this.canvasWidth/2)+.5, (this.canvasHeight/2)+.5);
    this.ctx.lineTo((this.canvasWidth)+.5, this.canvasHeight);
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.moveTo((this.canvasWidth/2)+.5, (this.canvasHeight/2)+.5);
    this.ctx.lineTo(0,this.canvasHeight);
    this.ctx.stroke(); */

		this.ctx.save();

		this.ctx.translate(this.canvasWidth / 2, this.canvasHeight / 2);
		this.ctx.rotate(this.radius * Math.PI / 80);

		this.ctx.fillStyle = this.background;

		this.ctx.fillRect(
			this.canvasWidth / 2 - (this.radius + this.thickness) * this.wRatio / 2,
			this.canvasHeight / 2 - (this.radius + this.thickness) * this.hRatio / 2,
			(this.radius + this.thickness) * this.wRatio,
			(this.radius + this.thickness) * this.hRatio
		);
		this.ctx.fillStyle = this.colors[this.color];

		this.ctx.fillRect(
			this.canvasWidth / 2 - this.radius / 2 * this.wRatio,
			this.canvasHeight / 2 - this.radius / 2 * this.hRatio,
			this.radius * this.wRatio,
			this.radius * this.hRatio
		);

		this.ctx.restore();
		if (
			this.radius * this.wRatio > this.canvasWidth + this.canvasWidth / 2 ||
			this.radius * this.hRatio > this.canvasHeight + this.canvasWidth / 2
		) {
			return false;
		} else {
			return true;
		}
	}

	backgroudOne() {
		let delta = (Date.now() - this.lastChecked) / 1000;
		this.radius += this.growthSpeed * delta;
		/*
    this.ctx.strokeStyle = this.colors[Math.floor(Math.random() * this.colors.length)]; // '#39ff14';
    this.ctx.lineWidth = 1.5;

    this.ctx.beginPath();
    this.ctx.moveTo((this.canvasWidth/2)+.5, (this.canvasHeight/2)+.5);
    this.ctx.lineTo(0, 0);
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.moveTo((this.canvasWidth/2)+.5, (this.canvasHeight/2)+.5);
    this.ctx.lineTo(this.canvasWidth, 0);
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.moveTo((this.canvasWidth/2)+.5, (this.canvasHeight/2)+.5);
    this.ctx.lineTo((this.canvasWidth)+.5, this.canvasHeight);
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.moveTo((this.canvasWidth/2)+.5, (this.canvasHeight/2)+.5);
    this.ctx.lineTo(0,this.canvasHeight);
    this.ctx.stroke(); */

		this.ctx.save();

		this.ctx.translate(this.canvasWidth / 2, this.canvasHeight / 2);
		this.ctx.rotate(this.radius * Math.PI / 80);

		this.ctx.fillStyle = this.colors[this.color];

		this.ctx.fillRect(
			this.canvasWidth / 2 - (this.radius + this.thickness) * this.wRatio / 2,
			this.canvasHeight / 2 - (this.radius + this.thickness) * this.hRatio / 2,
			(this.radius + this.thickness) * this.wRatio,
			(this.radius + this.thickness) * this.hRatio
		);

		this.ctx.fillStyle = this.background;

		this.ctx.fillRect(
			this.canvasWidth / 2 - this.radius / 2 * this.wRatio,
			this.canvasHeight / 2 - this.radius / 2 * this.hRatio,
			this.radius * this.wRatio,
			this.radius * this.hRatio
		);

		this.ctx.restore();
		if (
			this.radius * this.wRatio > this.canvasWidth + this.canvasWidth / 2 ||
			this.radius * this.hRatio > this.canvasHeight + this.canvasWidth / 2
		) {
			return false;
		} else {
			return true;
		}
	}

	backgroudThree() {
		let delta = (Date.now() - this.lastChecked) / 1000;
		this.radius += this.growthSpeed * 20 * delta;

		this.ctx.strokeStyle = this.colors[Math.floor(Math.random() * this.colors.length)]; // '#39ff14';
		this.ctx.lineWidth = 1.5;

		this.ctx.beginPath();
		this.ctx.moveTo(this.canvasWidth / 2 + 0.5, this.canvasHeight / 2 + 0.5);
		this.ctx.lineTo(0, 0);
		this.ctx.stroke();

		this.ctx.beginPath();
		this.ctx.moveTo(this.canvasWidth / 2 + 0.5, this.canvasHeight / 2 + 0.5);
		this.ctx.lineTo(this.canvasWidth, 0);
		this.ctx.stroke();

		this.ctx.beginPath();
		this.ctx.moveTo(this.canvasWidth / 2 + 0.5, this.canvasHeight / 2 + 0.5);
		this.ctx.lineTo(this.canvasWidth + 0.5, this.canvasHeight);
		this.ctx.stroke();

		this.ctx.beginPath();
		this.ctx.moveTo(this.canvasWidth / 2 + 0.5, this.canvasHeight / 2 + 0.5);
		this.ctx.lineTo(0, this.canvasHeight);
		this.ctx.stroke();

		this.ctx.fillStyle = this.colors[this.color];

		this.ctx.fillRect(
			this.canvasWidth / 2 - (this.radius + this.thickness) * this.wRatio / 2,
			this.canvasHeight / 2 - (this.radius + this.thickness) * this.hRatio / 2,
			(this.radius + this.thickness) * this.wRatio,
			(this.radius + this.thickness) * this.hRatio
		);

		this.ctx.fillStyle = this.background;

		this.ctx.fillRect(
			this.canvasWidth / 2 - this.radius / 2 * this.wRatio,
			this.canvasHeight / 2 - this.radius / 2 * this.hRatio,
			this.radius * this.wRatio,
			this.radius * this.hRatio
		);

		if (
			this.radius * this.wRatio > this.canvasWidth + this.canvasWidth / 2 ||
			this.radius * this.hRatio > this.canvasHeight + this.canvasWidth / 2
		) {
			return false;
		} else {
			return true;
		}
	}

	backgroudThree() {
		let delta = (Date.now() - this.lastChecked) / 1000;
		this.radius += this.growthSpeed * 20 * delta;

		this.ctx.strokeStyle = this.colors[this.color]; // '#39ff14';
		this.ctx.lineWidth = 1.5;

		this.ctx.beginPath();
		this.ctx.moveTo(this.canvasWidth / 2 + 0.5, this.canvasHeight / 2 + 0.5);
		this.ctx.lineTo(0, 0);
		this.ctx.stroke();

		this.ctx.beginPath();
		this.ctx.moveTo(this.canvasWidth / 2 + 0.5, this.canvasHeight / 2 + 0.5);
		this.ctx.lineTo(this.canvasWidth, 0);
		this.ctx.stroke();

		this.ctx.beginPath();
		this.ctx.moveTo(this.canvasWidth / 2 + 0.5, this.canvasHeight / 2 + 0.5);
		this.ctx.lineTo(this.canvasWidth + 0.5, this.canvasHeight);
		this.ctx.stroke();

		this.ctx.beginPath();
		this.ctx.moveTo(this.canvasWidth / 2 + 0.5, this.canvasHeight / 2 + 0.5);
		this.ctx.lineTo(0, this.canvasHeight);
		this.ctx.stroke();

		this.ctx.fillStyle = this.colors[this.color];

		this.ctx.fillRect(
			this.canvasWidth / 2 - (this.radius + this.thickness) * this.wRatio / 2,
			this.canvasHeight / 2 - (this.radius + this.thickness) * this.hRatio / 2,
			(this.radius + this.thickness) * this.wRatio,
			(this.radius + this.thickness) * this.hRatio
		);

		this.ctx.fillStyle = this.background;

		this.ctx.fillRect(
			this.canvasWidth / 2 - this.radius / 2 * this.wRatio,
			this.canvasHeight / 2 - this.radius / 2 * this.hRatio,
			this.radius * this.wRatio,
			this.radius * this.hRatio
		);

		if (
			this.radius * this.wRatio > this.canvasWidth + this.canvasWidth / 2 ||
			this.radius * this.hRatio > this.canvasHeight + this.canvasWidth / 2
		) {
			return false;
		} else {
			return true;
		}
	}

	backgroudFour() {
		let delta = (Date.now() - this.lastChecked) / 1000;
		this.radius += (this.growthSpeed * 10)  * delta;

        this.ctx.save();
       this.ctx.translate((this.canvasWidth / 2 ),(this.canvasHeight / 2));
   
       this.ctx.rotate((delta*10) * Math.PI / 80);


        this.ctx.fillStyle = this.background;
		this.ctx.fillRect(
            -((this.radius + this.thickness / 2)) * this.wRatio/2,
			-((this.radius + this.thickness / 2)) * this.hRatio/2,
			(this.radius + this.thickness) * this.wRatio,
			(this.radius + this.thickness) * this.hRatio
		);

		this.ctx.fillStyle = this.colors[this.color];

		this.ctx.fillRect(
            -(this.radius / 2 * this.wRatio),
			-(this.radius / 2 * this.hRatio),
			this.radius * this.wRatio,
			this.radius * this.hRatio
        );
        this.ctx.restore();

		if (
			this.radius * this.wRatio > this.canvasWidth + this.canvasWidth / 2 ||
			this.radius * this.hRatio > this.canvasHeight + this.canvasWidth / 2
		) {
			return false;
		} else {
			return true;
		}
    }
    
    backgroudFive () {
		let delta = (Date.now() - this.lastChecked) / 1000;
		this.radius += this.growthSpeed * 20 * delta;

		this.ctx.strokeStyle = this.background; // '#39ff14';
		this.ctx.lineWidth = 1.5;

		this.ctx.beginPath();
		this.ctx.moveTo(this.canvasWidth / 2 + 0.5, this.canvasHeight / 2 + 0.5);
		this.ctx.lineTo(0, 0);
		this.ctx.stroke();

		this.ctx.beginPath();
		this.ctx.moveTo(this.canvasWidth / 2 + 0.5, this.canvasHeight / 2 + 0.5);
		this.ctx.lineTo(this.canvasWidth, 0);
		this.ctx.stroke();

		this.ctx.beginPath();
		this.ctx.moveTo(this.canvasWidth / 2 + 0.5, this.canvasHeight / 2 + 0.5);
		this.ctx.lineTo(this.canvasWidth + 0.5, this.canvasHeight);
		this.ctx.stroke();

		this.ctx.beginPath();
		this.ctx.moveTo(this.canvasWidth / 2 + 0.5, this.canvasHeight / 2 + 0.5);
		this.ctx.lineTo(0, this.canvasHeight);
		this.ctx.stroke();

	
        this.ctx.fillStyle = this.background;

		this.ctx.fillRect(
            
            this.canvasWidth / 2 - (this.radius + this.thickness) * this.wRatio / 2,
			this.canvasHeight / 2 - (this.radius + this.thickness) * this.hRatio / 2,
			(this.radius + this.thickness) * this.wRatio,
			(this.radius + this.thickness) * this.hRatio
		);
	
        this.ctx.fillStyle = this.colors[this.color];

		this.ctx.fillRect(
            this.canvasWidth / 2 - this.radius / 2 * this.wRatio,
			this.canvasHeight / 2 - this.radius / 2 * this.hRatio,
			this.radius * this.wRatio,
			this.radius * this.hRatio
		);

		if (
			this.radius * this.wRatio > this.canvasWidth + this.canvasWidth / 2 ||
			this.radius * this.hRatio > this.canvasHeight + this.canvasWidth / 2
		) {
			return false;
		} else {
			return true;
		}
    }
    
    backgroudSix() {
		let delta = (Date.now() - this.lastChecked) / 1000;
		this.radius += (this.growthSpeed * 250)  * delta;

        this.ctx.save();
       this.ctx.translate((this.canvasWidth / 2 ),(this.canvasHeight / 2));
   
       this.ctx.rotate((delta*2) * Math.PI / 80);



       
       this.ctx.fillStyle = this.colors[this.color];

      
		this.ctx.fillRect(
            -((this.radius + this.thickness / 2)) * this.wRatio/2,
			-((this.radius + this.thickness / 2)) * this.hRatio/2,
			(this.radius + this.thickness) * this.wRatio,
			(this.radius + this.thickness) * this.hRatio
		);

        this.ctx.fillStyle = this.background;
		this.ctx.fillRect(
            -(this.radius / 2 * this.wRatio),
			-(this.radius / 2 * this.hRatio),
			this.radius * this.wRatio,
			this.radius * this.hRatio
        );
        this.ctx.restore();

		if (
			this.radius * this.wRatio > this.canvasWidth + this.canvasWidth / 2 ||
			this.radius * this.hRatio > this.canvasHeight + this.canvasWidth / 2
		) {
			return false;
		} else {
			return true;
		}
    }
}
