import React, { useEffect, useRef } from 'react';
import styles from './App.module.css';
import music from './media/music.mp3';
import pop from './media/circlePop.mp3';
import { Game } from './js/Game.js';
const App = () => {
	const canvas = useRef(null);
	let ctx = null;
	const musicRef = useRef(null);
	const popRef = useRef(null);

	let game;
	useEffect(() => {
	game = new Game(ctx, canvas, musicRef,popRef );
		return () => {};
	}, []);


	return (
		<div className={styles.App}>
			<canvas ref={canvas} />
			<audio ref={musicRef} autoPlay loop>
				<source src={music} type="audio/mpeg" />
			</audio>
			<audio ref={popRef}>
			<source src={pop} type="audio/mpeg" />

			</audio>
		</div>
	);
};

export default App;
